.skills__list {
  max-width: 450px;
  width: 95%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.skills__list-item {
  margin: 0.5em;
}

.skills-container {
  display: flex;
  justify-content: space-between;
}

.skills-column {
  flex: 1;
  margin-right: 20px; /* Adjust the spacing between columns */
}

@media (max-width: 768px) {
  .skills-container {
    flex-direction: column;
  }

  .skills-column {
    margin-right: 0;
    margin-bottom: 20px; /* Adjust the spacing between columns for mobile view */
  }
}

