.blog-title {
    border-bottom: 4px solid var(--clr-fg);
    margin-top: 30px;
    padding-bottom: 10px;
}

.article-container {
    border-bottom: 4px solid var(--clr-fg);
    margin: 0px 30px;
}

.date {
    border-bottom: 4px solid var(--clr-fg);
    margin-top: 30px;
}